/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, jsx } from "theme-ui"
import { graphql, PageProps } from "gatsby"

import type { PrivacyPolicyQuery } from "../../graphql-types"

import Layout from "components/Layout"
import MdContent from "components/common/MdContent"

interface PrivacyPolicyProps extends PageProps {
  data: PrivacyPolicyQuery
}

const PrivacyPolicy = ({ data, ...props }: PrivacyPolicyProps) => {
  return (
    <Layout seo={data.strapiPrivacyPolicy?.seo} location={props.location}>
      <Box as="article" sx={{ my: [12, null, 24] }}>
        <Container variant="layout.narrow">
          <MdContent source={data.strapiPrivacyPolicy?.content} />
        </Container>
      </Box>
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicy {
    strapiPrivacyPolicy {
      seo {
        shareImage {
          localFile {
            publicURL
          }
        }
        metaTitle
        metaDescription
      }
      content
    }
  }
`
